import React, { useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useLocation } from '@reach/router';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function WrapPageElement({ children }) {
    const { locales } = useStaticQuery(graphql`
        query {
            locales: allLocale {
                edges {
                    node {
                        language
                    }
                }
            }
        }
    `);

    const languages = locales.edges.map(edge => edge.node.language);
    const { defaultLanguage } = useI18next();
    const location = useLocation();
    
    useEffect(() => {
        if (languages.some(language => location.pathname.startsWith(`/${language}/`)))
            return;        

        let localePrefix = defaultLanguage;
        for (const browserLanguage of navigator.languages)
        {
            let supportedLanguage = languages.find(language => browserLanguage.startsWith(language));
            if (supportedLanguage) {
                localePrefix = supportedLanguage;
                break;
            }
        }

        navigate(`/${localePrefix}${location.pathname}`);
    }, []);

    return <>{children}</>;
}