module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"i18n","localeJsonNodeName":"locales","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://edelcoin.swisscows.com","languages":["en","de","fr","es"],"defaultLanguage":"en","trailingSlash":"always","pages":[{"matchPath":"/ignored-page","languages":["en"]}],"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
